import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4a4ce94e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fs-9 fw-bold text-gray-800 mb-0" }
const _hoisted_2 = { class: "fs-7 text-gray-400" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: _ctx.to,
    class: _normalizeClass(["d-flex flex-column flex-center h-100 p-6 bg-hover-light border-bottom border-end", _ctx.disabled && 'disabled'])
  }, {
    default: _withCtx(() => [
      _createElementVNode("span", {
        class: _normalizeClass(["svg-icon svg-icon-3x mb-2", (_ctx.disabled && 'svg-icon-gray', !_ctx.disabled && 'svg-icon-primary')])
      }, [
        _createVNode(_component_inline_svg, { src: _ctx.src }, null, 8, ["src"])
      ], 2),
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.title), 1),
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.description), 1)
    ]),
    _: 1
  }, 8, ["to", "class"]))
}