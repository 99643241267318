
import { defineComponent, onMounted, ref, computed, watch } from "vue";
import { useRoute } from "vue-router";
import { MenuComponent } from "@/assets/ts/components";
import MainMenuConfig from "@/core/config/MainMenuConfig";
import { headerMenuIcons } from "@/core/helpers/config";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { supabase } from "@/service/SupabaseService";
import { definitions } from "@/types/supabase";
import { setModal } from "@/core/helpers/config";

export default defineComponent({
  name: "KTMenu",
  components: {},
  setup() {
    const route = useRoute();
    const store = useStore();
    store.dispatch(Actions.FETCH_MENU_PLATFORM);
    let platforms = ref();
    store.dispatch(Actions.FETCH_USER_PROFILE);
    const user = computed(() => {
      return store.getters.getUser;
    });

    const watchPlateforms = async () => {
      supabase
        .from<definitions["platforms"]>(
          `platforms:adminUserId=eq.${user.value.data.id}`
        )
        .on("INSERT", (payload) => {
          platforms.value = [...platforms.value, payload.new];
        })
        .on("UPDATE", async (payload) => {
          platforms.value = await platforms.value
            .map((platform) =>
              platform.id == payload.old.id
                ? {
                    ...platform,
                    name: payload.new.name,
                    deletedAt: payload.new.deletedAt,
                  }
                : platform
            )
            .filter((platform) => platform.deletedAt == null);
        })
        .on("DELETE", (payload) => {
          platforms.value = platforms.value.filter(
            (platform) => platform.id != payload.old.id
          );
        })
        .subscribe(() => {
          console.log("subsuscibed");
        });
    };
    const getPlatformsByUserId = async () => {
      try {
        const { data: platformlist, error } = await supabase
          .from<definitions["platforms"]>("platforms")
          .select("id,name,deletedAt")
          .eq("adminUserId", Number(user.value.data.id))
          .is("deletedAt", null);
        if (error) throw error;
        platforms.value = platformlist;
      } catch (error) {
        console.error(error);
      }
    };
    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    watch(
      () => user.value.data,
      async () => {
        getPlatformsByUserId();
        watchPlateforms();
      }
    );

    onMounted(() => {
      MenuComponent.reinitialization();
    });
    return {
      hasActiveChildren,
      headerMenuIcons,
      MainMenuConfig,
      platforms,
      setModal,
    };
  },
});
