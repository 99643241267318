
import { defineComponent } from "vue";
import PanelAdminLink from "@/components/shared/PanelAdminLink.vue";

export default defineComponent({
  name: "kt-quick-links-menu",
  components: { PanelAdminLink },
  setup() {
    return {};
  },
});
