
import { defineComponent, reactive, ref } from "vue";
import ModalLayout from "@/components/modals/ModalLayout.vue";
import { setModal } from "@/core/helpers/config";
import type { ElForm } from "element-plus";
export default defineComponent({
  name: "addRole",
  components: {
    ModalLayout,
  },
  props: {},
  setup() {
    const roleName = ref<string>("");
    const ListPermissions = [
      { name: "Administrator Access", actions: ["All"] },
      { name: "User Management", actions: ["Read", "Write", "Create"] },
      { name: "Content Management", actions: ["Read", "Write", "Create"] },
      { name: "Financial Management", actions: ["Read", "Write", "Create"] },
      { name: "Reporting", actions: ["Read", "Write", "Create"] },
      { name: "Payroll", actions: ["Read", "Write", "Create"] },
      { name: "Disputes Management", actions: ["Read", "Write", "Create"] },
      { name: "API Controls", actions: ["Read", "Write", "Create"] },
      { name: "Database Management", actions: ["Read", "Write", "Create"] },
      { name: "Repository Management", actions: ["Read", "Write", "Create"] },
    ];
    const ruleFormRef = ref<InstanceType<typeof ElForm>>();
    const resetForm = (formEl: InstanceType<typeof ElForm> | undefined) => {
      if (!formEl) return;
      formEl.resetFields();
    };
    const newRole = ref({
      name: "",
      Permissions: [
        { name: "Administrator Access", actions: [] },
        { name: "User Management", actions: [] },
        { name: "Content Management", actions: [] },
        { name: "Financial Management", actions: [] },
        { name: "Reporting", actions: [] },
        { name: "Payroll", actions: [] },
        { name: "Disputes Management", actions: [] },
        { name: "API Controls", actions: [] },
        { name: "Database Management", actions: [] },
        { name: "Repository Management", actions: [] },
      ],
    });
    const rules = reactive({
      name: [
        {
          required: true,
          message: "Please input role name",
          trigger: "blur",
        },
        {
          min: 3,
          message: "Length should be at least 3 caracteres",
          trigger: "blur",
        },
      ],
      permission: [
        {
          type: "array",
          required: true,
          message: "Please select at least one activity type",
          trigger: "change",
        },
      ],
    });
    return {
      setModal,
      roleName,
      ListPermissions,
      resetForm,
      ruleFormRef,
      newRole,
      rules,
    };
  },
});
