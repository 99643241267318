
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";

//Modals
import KTCreateNewTicket from "@/components/modals/wizards/CreateNewTicketModal.vue";
import KTCreatePlatformModal from "@/components/modals/wizards/createPlatform/CreatePlatformModal.vue";
import KTCreateNewBranche from "@/components/modals/wizards/CreateBranche.vue";
import KTAddUser from "@/components/modals/wizards/AddUser.vue";
import KTAllBranches from "@/components/modals/wizards/Allbranches.vue";
import KTAllCommits from "@/components/modals/wizards/AllCommits.vue";
import KTAllPull from "@/components/modals/wizards/AllPull.vue";
import AddRole from "@/components/modals/wizards/AddRole.vue";

export default defineComponent({
  components: {
    KTCreateNewTicket,
    KTCreatePlatformModal,
    KTCreateNewBranche,
    KTAddUser,
    KTAllBranches,
    KTAllCommits,
    KTAllPull,
    AddRole,
  },
  setup() {
    const store = useStore();
    const modalState = computed(() => {
      return store.getters.getModalState;
    });
    return { modalState };
  },
});
