<template>
  <div class="w-100">
    <!--begin::Input group-->
    <div class="row g-9 mb-2">
      <!--platform Name-->
      <div class="fv-row mb-4 fv-plugins-icon-container col-md-8">
        <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
          <span class="required">
            {{ $t("platformName") }}
          </span>
        </label>
        <Field
          class="form-control form-control-lg form-control-solid"
          type="text"
          placeholder=""
          name="platformName"
          :value="platformName"
          @input="updateValue"
          @blur="generateKey"
          autocomplete="off"
        />
        <div class="fv-plugins-message-container">
          <div class="fv-help-block fs-8">
            <ErrorMessage name="platformName" />
          </div>
        </div>
      </div>

      <!--platform KEY-->
      <div class="fv-row mb-4 fv-plugins-icon-container col-md-4">
        <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
          <span class="required">
            {{ $t("key") }}
          </span>
        </label>
        <Field name="keyValue" v-model="keyValueLocal">
          <el-input
            class="key-size"
            v-model="keyValueLocal"
            type="text"
            placeholder=""
            name="keyValue"
            :value="keyValue"
            autocomplete="off"
          />
        </Field>
        <!--begin::Notice-->
        <div v-if="isKeyUsed" class="text-danger fw-bold fs-8">
          {{ $t("keyUsedError") }}
        </div>
        <!--end::Notice-->
        <div class="fv-plugins-message-container">
          <div class="fv-help-block fs-8">
            <ErrorMessage name="keyValue" />
          </div>
        </div>
      </div>
    </div>
    <!--Platform Description-->
    <div class="fv-row mb-4 fv-plugins-icon-container">
      <!--begin::Label-->
      <label class="fs-6 fw-bold mb-2">
        {{ $t("platformDescription") }}
      </label>
      <!--end::Label-->
      <!--begin::Input-->
      <Field
        type="text"
        as="textarea"
        class="form-control form-control-solid"
        rows="4"
        name="platformDescription"
        :value="platformDescription"
        @input="updateDesc"
      />
      <!--end::Input-->
    </div>

    <!--Field of activities-->

    <div class="d-flex flex-column mb-4 fv-row">
      <!--begin::Label-->
      <label class="d-flex align-items-center fs-6 fw-bold mb-2">
        <span class="required">
          {{ $t("activities") }}
        </span>
      </label>
      <!--end::Label-->
      <Field name="activities" v-model="activitiesLocal">
        <el-select
          multiple
          filterable
          allow-create
          default-first-option
          placeholder=""
          name="activities"
          v-model="activitiesLocal"
          class="input-cs-color"
        >
          <el-option
            v-for="activity in platformActivities"
            :label="activity.name"
            :value="activity.name"
            :key="activity.id"
          ></el-option>
        </el-select>
      </Field>
      <div class="fv-plugins-message-container">
        <div class="fv-help-block fs-8">
          <ErrorMessage name="activities" />
        </div>
      </div>
    </div>

    <!--Release Date-->
    <div class="fv-row mb-4 fv-plugins-icon-container position-relative">
      <label class="required fs-6 fw-bold mb-2">
        {{ $t("releaseDate") }}
      </label>

      <div class="position-relative align-items-center">
        <div class="symbol symbol-20px me-4 position-absolute ms-4">
          <span class="symbol-label bg-secondary">
            <span class="svg-icon">
              <inline-svg src="svg/icons/gen025.svg" />
            </span>
          </span>
        </div>
        <Field name="releaseDate" v-model="releaseDateLocal">
          <el-date-picker
            placeholder="Select a date"
            class="w-100"
            name="releaseDate"
            :disabled-date="disabledDate"
            v-model="releaseDateLocal"
          />
        </Field>
        <div class="fv-plugins-message-container">
          <div class="fv-help-block fs-8">
            <ErrorMessage name="releaseDate" />
          </div>
        </div>
      </div>
    </div>

    <!--Files-->
    <div class="fw-bold me-5 mb-4">
      <label class="fs-6 mb-2">{{ $t("attachments") }}</label>
      <Upload v-model:fileList="filesLocal" />
    </div>

    <!--end::Input group-->
  </div>
</template>
<script>
import { ErrorMessage, Field } from "vee-validate";
import Upload from "@/components/shared/Upload.vue";

export default {
  props: {
    platformName: String,
    platformDescription: String,
    activities: Array,
    releaseDate: Date,
    platformActivities: Array,
    keyValue: String,
    isKeyUsed: Boolean,
    fileList: Array,
    generateKey: {
      type: Function,
    },
  },
  components: {
    ErrorMessage,
    Field,

    Upload,
  },

  computed: {
    activitiesLocal: {
      get: function () {
        return this.activities;
      },
      set: function (value) {
        this.$emit("update:activities", value);
      },
    },
    releaseDateLocal: {
      get: function () {
        return this.releaseDate;
      },
      set: function (value) {
        this.$emit("update:releaseDate", value);
      },
    },
    filesLocal: {
      get: function () {
        return this.fileList;
      },
      set: function (value) {
        this.$emit("update:fileList", value);
      },
    },

    keyValueLocal: {
      get: function () {
        return this.keyValue;
      },
      set: function (value) {
        this.$emit("update:keyValue", value);
      },
    },
  },

  setup(props, context) {
    function updateValue(event) {
      context.emit("update:platformName", event.target.value);
    }
    function updateDesc(event) {
      context.emit("update:platformDescription", event.target.value);
    }

    const disabledDate = (time) => {
      return time.getTime() < Date.now();
    };

    return {
      updateValue,
      updateDesc,
      disabledDate,
    };
  },
};
</script>
<style lang="scss" scoped-slot>
.el-date-editor.el-input {
  width: 100% !important;
}
.el-select {
  width: 100%;
}
.picture-card {
  position: relative;
}
.upload-html {
  height: 5rem;
}

.key-size {
  .el-input__inner {
    min-height: 46px !important;
    border: 1px solid #e4e6ef !important;
    font-size: 1.15rem !important;
    background-color: #e9ecef !important;
    border-color: #e9ecef !important;
    color: #5e6278 !important;
    font-weight: 500 !important;
  }
}
.center-title {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
