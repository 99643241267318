import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4abe5acd"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "kt_aside",
  class: "aside aside-hoverable aside-light mobile-nav",
  "data-kt-drawer": "true",
  "data-kt-drawer-name": "aside",
  "data-kt-drawer-activate": "{default: true, lg: false}",
  "data-kt-drawer-overlay": "true",
  "data-kt-drawer-width": "{default:'200px', '300px': '250px'}",
  "data-kt-drawer-direction": "start",
  "data-kt-drawer-toggle": "#kt_aside_mobile_toggle"
}
const _hoisted_2 = { class: "aside-menu flex-column-fluid" }
const _hoisted_3 = {
  class: "aside-footer flex-column-auto pt-5 pb-7 px-20",
  id: "kt_aside_footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTMenu = _resolveComponent("KTMenu")!
  const _component_KTUserMenu = _resolveComponent("KTUserMenu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_KTMenu)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_KTUserMenu)
    ])
  ]))
}