<template>
  <div class="w-100 h-450px">
    <div class="d-flex flex-column mb-10 fv-row" v-if="groupsPlatform">
      <!--begin::Label-->
      <label class="d-flex align-items-center fs-6 fw-bold mb-2 required">
        <span>
          {{ $t("selectGroups") }}
        </span>
      </label>
      <!--end::Label-->
      <Field name="groupSelected" v-model="groupSelectedLocal">
        <el-select
          name="groupSelected"
          multiple
          placeholder=""
          default-first-option
          v-model="groupSelectedLocal"
        >
          <el-option
            v-for="group in groupsPlatform"
            :label="group.name"
            :value="group.id"
            :key="group.id"
            :disabled="group.id === 1"
          >
          </el-option>
        </el-select>
      </Field>

      <ErrorMessage
        class="fv-plugins-message-container invalid-feedback fs-8"
        name="groupSelected"
      />
    </div>

    <!-- select team manager -->
    <div class="fv-row mb-4" v-if="platformMembers">
      <!--begin::Label-->
      <label class="form-label required">
        {{ $t("selectPlatformManager") }}
      </label>
      <!--end::Label-->

      <!--begin::Input-->
      <Field name="teamManager" v-model="teamManagerLocal">
        <el-select
          filterable
          name="teamManager"
          as="select"
          v-model="teamManagerLocal"
        >
          <el-option
            :value="member.users.id"
            v-for="member in platformMembers"
            :key="member.users.id"
            :label="`${member.users.firstName} ${member.users.lastName}`"
            class="pt-2 pb-2"
          >
            <div
              v-if="member.users.firstName && member.users.lastName"
              class="d-flex align-items-center"
            >
              <!--begin::Avatar-->
              <div class="symbol symbol-35px symbol-circle">
                <span
                  :class="`bg-light-success text-success`"
                  class="symbol-label fw-bold"
                >
                  {{ member.users.firstName.charAt(0).toUpperCase() }}
                  {{ member.users.lastName.charAt(0).toUpperCase() }}
                </span>
              </div>
              <!--end::Avatar-->

              <!--begin::Details-->
              <div class="ms-5">
                <span
                  class="fs-5 fw-bolder text-gray-900 text-hover-primary mb-2"
                >
                  {{ member.users.firstName }} {{ member.users.lastName }}
                </span>
              </div>
              <!--end::Details-->
            </div>
          </el-option>
        </el-select>
      </Field>
      <ErrorMessage
        class="fv-plugins-message-container invalid-feedback fs-8"
        name="teamManager"
      />
      <!--end::Input-->
    </div>

    <!--end::Heading-->
  </div>
</template>

<script>
import { ErrorMessage, Field } from "vee-validate";
export default {
  props: {
    groupSelected: Array,
    teamManager: Number,
    platformMembers: Array,
    groupsPlatform: Array,
  },
  setup() {
    return {};
  },
  components: {
    ErrorMessage,
    Field,
  },

  computed: {
    groupSelectedLocal: {
      get: function () {
        return this.groupSelected;
      },
      set: function (value) {
        this.$emit("update:groupSelected", value);
      },
    },
    teamManagerLocal: {
      get: function () {
        return this.teamManager;
      },
      set: function (value) {
        this.$emit("update:teamManager", value);
      },
    },
  },
};
</script>
<style lang="scss" scoped-slot>
.el-select-dropdown {
  background-color: transparent;
}
.el-select-dropdown__item {
  height: auto;
}

.bg-light-success {
  padding-left: 0rem !important;
}
.el-select-dropdown__item {
  line-height: 25px;
}
.symbol-circle {
  span {
    padding-left: 0rem !important;
  }
}
</style>
