import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "stepper-nav pb-4 gap-2 mx-0 justify-content-between" }
const _hoisted_2 = {
  class: "stepper-item current me-2 ms-0",
  "data-kt-stepper-element": "nav"
}
const _hoisted_3 = { class: "stepper-title fs-5" }
const _hoisted_4 = {
  class: "stepper-item me-2 ms-0",
  "data-kt-stepper-element": "nav"
}
const _hoisted_5 = { class: "stepper-title fs-5" }
const _hoisted_6 = {
  class: "stepper-item me-2 ms-0",
  "data-kt-stepper-element": "nav"
}
const _hoisted_7 = { class: "stepper-title fs-5" }
const _hoisted_8 = {
  class: "stepper-item me-2 ms-0",
  "data-kt-stepper-element": "nav"
}
const _hoisted_9 = { class: "stepper-title fs-5" }
const _hoisted_10 = {
  class: "stepper-item me-2 ms-0",
  "data-kt-stepper-element": "nav"
}
const _hoisted_11 = { class: "stepper-title fs-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t("platformInfo")), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.$t("buildATeam")), 1)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("h3", _hoisted_7, _toDisplayString(_ctx.$t("platformType")), 1)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("h3", _hoisted_9, _toDisplayString(_ctx.$t("Technologies")), 1)
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("h3", _hoisted_11, _toDisplayString(_ctx.$t("ticketsTable.completed")), 1)
    ])
  ]))
}