import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  href: "#",
  class: "text-dark"
}
const _hoisted_2 = {
  href: "#",
  class: "text-dark"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_ModalLayout = _resolveComponent("ModalLayout")!

  return (_openBlock(), _createBlock(_component_ModalLayout, {
    maxWidth: "1120px",
    title: _ctx.data[0].prefix + ' : ' + _ctx.data.length + ' ' + _ctx.$t('branches')
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_table, {
        data: _ctx.data,
        style: {"width":"100%"},
        height: "500"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            label: ` ${_ctx.$t('branchesPage.repository')}  `,
            width: "200"
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("a", _hoisted_1, _toDisplayString(scope.row.repository), 1)
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_table_column, {
            label: ` ${_ctx.$t('branches')}  `,
            width: "350"
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("a", _hoisted_2, _toDisplayString(scope.row.branch), 1)
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_table_column, {
            label: ` ${_ctx.$t('branchesPage.pullRequest')}  `
          }, {
            default: _withCtx((scope) => [
              (scope.row.pullRequest)
                ? (_openBlock(), _createBlock(_component_el_tag, {
                    key: 0,
                    type: scope.row.pullRequest === 'MERGED' ? '' : 'success',
                    "disable-transitions": ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(scope.row.pullRequest), 1)
                    ]),
                    _: 2
                  }, 1032, ["type"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_table_column, {
            label: ` ${_ctx.$t('branchesPage.action')}  `,
            align: "right"
          }, {
            default: _withCtx((scope) => [
              (!scope.row.pullRequest)
                ? (_openBlock(), _createBlock(_component_el_button, {
                    key: 0,
                    type: "text",
                    size: "small"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("create") + " " + _ctx.$t("branchesPage.pullRequest")), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["label"])
        ]),
        _: 1
      }, 8, ["data"])
    ]),
    _: 1
  }, 8, ["title"]))
}