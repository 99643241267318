
import { defineComponent, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { setLogin } from "@/core/helpers/user";
import Avatar from "@/components/shared/Avatar.vue";

export default defineComponent({
  name: "kt-user-menu",
  components: { Avatar },
  setup() {
    const router = useRouter();
    const i18n = useI18n();

    i18n.locale.value = localStorage.getItem("lang")
      ? (localStorage.getItem("lang") as string)
      : "fr";
    const countries = {
      en: {
        flag: "svg/flags/united-states.svg",
        name: "English",
      },
      fr: {
        flag: "svg/flags/france.svg",
        name: "French",
      },
    };

    const signOut = () => {
      window.localStorage.removeItem("token");
      setLogin({});
      router.push({ name: "sign-in" });
    };

    const setLang = (lang) => {
      localStorage.setItem("lang", lang);
      i18n.locale.value = lang;
    };

    const currentLanguage = (lang) => {
      return i18n.locale.value === lang;
    };

    const currentLangugeLocale = computed(() => {
      return countries[i18n.locale.value];
    });

    return {
      signOut,
      setLang,
      currentLanguage,
      currentLangugeLocale,
    };
  },
});
