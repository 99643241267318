import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plus = _resolveComponent("plus")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_upload = _resolveComponent("el-upload")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["upload-file", { hide: _ctx.hideButtonAdd }])
  }, [
    _createVNode(_component_el_upload, {
      action: "#",
      "list-type": "picture-card",
      "auto-upload": false,
      limit: _ctx.limit,
      name: _ctx.name,
      "on-change": _ctx.handleChange,
      multiple: "",
      "file-list": _ctx.fileList,
      "on-remove": _ctx.handleRemove
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_icon, null, {
          default: _withCtx(() => [
            _createVNode(_component_plus)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["limit", "name", "on-change", "file-list", "on-remove"])
  ], 2))
}