
import { defineComponent, reactive, ref } from "vue";
import { setModal } from "@/core/helpers/config";
import ModalLayout from "@/components/modals/ModalLayout.vue";
import type { ElForm } from "element-plus";
interface IBranche {
  repository: string;
  type: string;
  fromBranch: string;
  name: string;
}
export default defineComponent({
  components: { ModalLayout },
  name: "create-branche-modal",
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  setup() {
    const newBranche = ref<IBranche>({
      repository: "",
      type: "",
      fromBranch: "",
      name: "",
    });
    const repository = ref([
      {
        label: "repository 1 ",
        value: "repository-1",
      },
      {
        label: "repository 2",
        value: "repository-2",
      },
      {
        label: "repository 3",
        value: "repository-3",
      },
    ]);
    const type = ref([
      {
        label: "Feat ",
        value: "Feat",
      },
      {
        label: "Fix",
        value: "Fix",
      },
      {
        label: "Add",
        value: "Add",
      },
    ]);

    const fromBranch = ref([
      {
        label: "from Branch 1 ",
        value: "fromBranch-1",
      },
      {
        label: "from Branch 2",
        value: "fromBranch-2",
      },
      {
        label: "from Branch 3",
        value: "fromBranch-3",
      },
    ]);

    const ruleFormRef = ref<InstanceType<typeof ElForm>>();
    const submitForm = (formEl: InstanceType<typeof ElForm> | undefined) => {
      if (!formEl) return;
      formEl.validate((valid) => {
        if (valid) {
          console.log("submit!");
          formEl.resetFields();
          setModal("");
        } else {
          console.log("error submit!");
          return false;
        }
      });
    };
    const resetForm = (formEl: InstanceType<typeof ElForm> | undefined) => {
      if (!formEl) return;
      formEl.resetFields();
    };

    const rules = reactive({
      name: [
        {
          required: true,
          message: "Please input branche name",
          trigger: "blur",
        },
        {
          min: 3,
          message: "Length should be at least 3 caracteres",
          trigger: "blur",
        },
      ],
      repository: [
        {
          required: true,
          message: "Please select repository ",
          trigger: "change",
        },
      ],
      type: [
        {
          required: true,
          message: "Please select type ",
          trigger: "change",
        },
      ],
      fromBranch: [
        {
          required: true,
          message: "Please select from branch",
          trigger: "change",
        },
      ],
    });

    return {
      setModal,
      newBranche,
      repository,
      type,
      fromBranch,
      resetForm,
      ruleFormRef,
      rules,
      submitForm,
    };
  },
});
