
import { defineComponent, computed } from "vue";
import { toolbarWidthFluid } from "@/core/helpers/config";
import { setModal } from "@/core/helpers/config";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "KToolbar",
  props: {
    breadcrumbs: Array,
    title: String,
  },
  components: {},
  setup() {
    const handleChangeStateModal = () => {
      setModal("KTCreateNewTicket");
    };
    const displayModalPlatform = () => {
      setModal("KTCreatePlatformModal");
    };
    const router = useRouter();
    const displayButton = computed(() => {
      const pathName = router.currentRoute.value.path.split("/")[1];
      return pathName === "platform";
    });
    return {
      toolbarWidthFluid,
      displayButton,
      handleChangeStateModal,
      displayModalPlatform,
    };
  },
});
