
import { defineComponent, ref } from "vue";
import ModalLayout from "@/components/modals/ModalLayout.vue";

export default defineComponent({
  components: { ModalLayout },
  name: "all-commits",
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  setup() {
    const prefixTicket = ref("DFP-286");

    const data = ref([
      {
        author: "Kira",
        orderID: "04b8484",
        Branche: "DFP-286 Create default values migration mechanism",
        date: "05/02/2020",
        files: "	27 files",
      },
      {
        author: "Sephiroth",
        orderID: "9e4dd3f",
        Branche: "FP-286 Create default values migration",
        date: "02/04/2020",
        files: "	3 files",
      },
      {
        author: "Sephiroth",
        orderID: "9e4dd3f",
        Branche: "FP-286 Create default values migration",
        date: "02/04/2020",
        files: "	3 files",
      },
      {
        author: "Sephiroth",
        orderID: "9e4dd3f",
        Branche: "FP-286 Create default values migration",
        date: "02/04/2020",
        files: "	3 files",
      },
      {
        author: "Sephiroth",
        orderID: "9e4dd3f",
        Branche: "FP-286 Create default values migration",
        date: "02/04/2020",
        files: "	3 files",
      },
      {
        author: "Sephiroth",
        orderID: "9e4dd3f",
        Branche: "FP-286 Create default values migration",
        date: "02/04/2020",
        files: "	3 files",
      },
    ]);

    return {
      data,
      prefixTicket,
    };
  },
});
