
import { defineComponent } from "vue";
import KTLogo from "@/components/layouts/mainLayout/header/Logo.vue";
import { headerWidthFluid } from "@/core/helpers/config";

export default defineComponent({
  name: "KTHeader",
  props: {
    title: String,
  },
  components: {
    KTLogo,
  },
  setup() {
    return {
      headerWidthFluid,
    };
  },
});
