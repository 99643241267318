
import { computed, defineComponent } from "vue";
import { Plus } from "@element-plus/icons-vue";
export default defineComponent({
  props: {
    limit: {
      type: Number,
      default: 3,
    },
    fileList: {
      type: Array,
    },
  },
  components: {
    Plus,
  },
  setup(props, ctx) {
    const handleChange = (file, fileList) => {
      ctx.emit("update:fileList", fileList);
      ctx.emit("handleAddFile", file, fileList);
    };
    const handleRemove = (file, fileList) => {
      ctx.emit("update:fileList", fileList);
      ctx.emit("handleRemoveFile", file, fileList);
    };
    const hideButtonAdd = computed(() => {
      if (props.limit == props.fileList?.length) {
        return true;
      } else return false;
    });
    return {
      handleChange,
      handleRemove,
      hideButtonAdd,
    };
  },
});
