
import { defineComponent } from "vue";
import KTNotificationsMenu from "@/components/layouts/mainLayout/header/partials/NotificationsMenu.vue";
import KTQuickLinksMenu from "@/components/layouts/mainLayout/header/partials/QuickLinksMenu.vue";
import KTUserMenu from "@/components/layouts/mainLayout/header/partials/UserMenu.vue";
import Avatar from "@/components/shared/Avatar.vue";

export default defineComponent({
  name: "topbar",
  components: {
    KTNotificationsMenu,
    KTQuickLinksMenu,
    KTUserMenu,
    Avatar,
  },
});
