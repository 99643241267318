
import { defineComponent, ref } from "vue";
import ModalLayout from "@/components/modals/ModalLayout.vue";

export default defineComponent({
  components: { ModalLayout },
  name: "all-branches",
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  setup() {
    const data = ref([
      {
        repository: "dev-factory-api",
        branch: "DFP-286-Create-users-factory",
        pullRequest: "OPEN",
        prefix: "DFP-286",
      },
      {
        repository: "dev-factory-platform",
        branch: "DFP-286-integration-member-dashboard",
        pullRequest: "MERGED",
        prefix: "DFP-286",
      },
      {
        repository: "dev-factory-platform",
        branch: "DFP-286-integration-member-dashboard",
        pullRequest: "",
        prefix: "DFP-286",
      },
      {
        repository: "dev-factory-platform",
        branch: "DFP-286-integration-member-dashboard",
        pullRequest: "",
        prefix: "DFP-286",
      },
      {
        repository: "dev-factory-platform",
        branch: "DFP-286-integration-member-dashboard",
        pullRequest: "",
        prefix: "DFP-286",
      },
      {
        repository: "dev-factory-platform",
        branch: "DFP-286-integration-member-dashboard",
        pullRequest: "",
        prefix: "DFP-286",
      },
      {
        repository: "dev-factory-platform",
        branch: "DFP-286-integration-member-dashboard",
        pullRequest: "",
        prefix: "DFP-286",
      },
      {
        repository: "dev-factory-platform",
        branch: "DFP-286-integration-member-dashboard",
        pullRequest: "",
        prefix: "DFP-286",
      },
      {
        repository: "dev-factory-platform",
        branch: "DFP-286-integration-member-dashboard",
        pullRequest: "",
        prefix: "DFP-286",
      },
      {
        repository: "dev-factory-platform",
        branch: "DFP-286-integration-member-dashboard",
        pullRequest: "",
        prefix: "DFP-286",
      },
    ]);

    return {
      data,
    };
  },
});
