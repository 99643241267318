
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    src: {
      type: String,
      default: "svg/shared-component/custom-box/icon-default-image.svg",
    },
    to: { type: Object, required: true },
    title: { type: String },
    description: { type: String },
    disabled: { type: Boolean, default: false },
  },
  components: {},
  setup() {
    return {};
  },
});
