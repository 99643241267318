
import { defineComponent, onMounted, onUpdated } from "vue";

import { ToggleComponent } from "@/assets/ts/components/_ToggleComponent";
import KTMenu from "@/components/layouts/mainLayout/aside/Menu.vue";
import KTUserMenu from "@/components/layouts/mainLayout/header/partials/UserMenu.vue";

export default defineComponent({
  name: "KTAside",
  components: {
    KTMenu,
    KTUserMenu,
  },
  props: {
    lightLogo: String,
  },
  setup() {
    onMounted(() => {
      ToggleComponent.reinitialization();
    });

    onUpdated(() => {
      ToggleComponent.bootstrap();
    });

    return {};
  },
});
