const DocMenuConfig = [
  {
    pages: [
      {
        heading: "Accueil",
        route: "/home",
        svgIcon: "svg/icons/art002.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    heading: "Platforms",
    route: "/platform",
    pages: [
      {
        sectionTitle: "Éléments récents",
        route: "/platform4",
        svgIcon: "svg/icons/com012.svg",
        fontIcon: "bi-chat-left",
        sub: [
          {
            heading: "Dev Factory",
            route: "/platform/overview",
          },
        ],
      },
      {
        heading: "Afficher tous les platform",
        route: "/platform/all-platforms",
        svgIcon: "svg/icons/gen014.svg",
        fontIcon: "bi-calendar3-event",
      },
      {
        heading: "Créer un platform",
        svgIcon: "svg/icons/gen014.svg",
        fontIcon: "bi-calendar3-event",
        modalName: "KTCreatePlatformModal",
      },
    ],
  },
  {
    pages: [
      {
        heading: "How works",
        route: "/howworks",
        svgIcon: "svg/icons/gen019.svg",
        fontIcon: "bi-layers",
      },
    ],
  },

  {
    sectionTitle: "authentication",
    svgIcon: "svg/icons//teh004.svg",
    fontIcon: "bi-sticky",
    sub: [
      {
        sectionTitle: "basicFlow",
        sub: [
          {
            heading: "signIn",
            route: "/sign-in",
          },
          {
            heading: "signUp",
            route: "/sign-up",
          },
          {
            heading: "signUpMember",
            route: "/sign-up-member",
          },
          {
            heading: "passwordReset",
            route: "/password-reset",
          },
        ],
      },
      {
        heading: "error404",
        route: "/404",
      },
      {
        heading: "error500",
        route: "/500",
      },
    ],
  },
  {
    pages: [],
  },
];

export default DocMenuConfig;
