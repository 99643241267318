
import { defineComponent, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import KTAside from "@/components/layouts/mainLayout/aside/Aside.vue";
import KTHeader from "@/components/layouts/mainLayout/header/Header.vue";
import KTFooter from "@/components/layouts/mainLayout/footer/Footer.vue";
import HtmlClass from "@/core/services/LayoutService";
import KTToolbar from "@/components/layouts/mainLayout/toolbar/Toolbar.vue";
import KTScrollTop from "@/components/layouts/mainLayout/extras/ScrollTop.vue";

import KTLoader from "@/components/shared/Loader.vue";
import { Actions } from "@/store/enums/StoreEnums";
import { MenuComponent, DrawerComponent } from "@/assets/ts/components/index";
import {
  loaderEnabled,
  contentWidthFluid,
  loaderLogo,
  asideEnabled,
  subheaderDisplay,
  themeLightLogo,
} from "@/core/helpers/config";
import ModalLoader from "./ModalLoader.vue";
import Logo from "./header/Logo.vue";
import Menu from "./header/Menu.vue";
import Topbar from "./header/Topbar.vue";

export default defineComponent({
  name: "Layout",
  components: {
    KTAside,
    KTHeader,
    KTFooter,
    KTToolbar,
    KTScrollTop,
    KTLoader,
    ModalLoader,
    Logo,
    Menu,
    Topbar,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    // show page loading
    store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
    store.dispatch(Actions.FETCH_USER_TOKEN);

    // initialize html element classes
    HtmlClass.init();

    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });

    const tokenUser = computed(() => {
      return store.getters.userToken;
    });

    const breadcrumbs = computed(() => {
      return store.getters.pageBreadcrumbPath;
    });
    onMounted(() => {
      //check if current user is authenticated
      if (tokenUser.value === "" || tokenUser.value === null) {
        router.push({ name: "sign-in" });
      }

      DrawerComponent.bootstrap();
      DrawerComponent.updateAll();

      // Simulate the delay page loading
      setTimeout(() => {
        // Remove page loader after some time
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
      }, 200);
    });

    watch(
      () => route.path,
      () => {
        MenuComponent.hideDropdowns(undefined);

        DrawerComponent.hideAll();

        // check if current user is authenticated
        if (tokenUser.value === "" || tokenUser.value === null) {
          router.push({ name: "sign-in" });
        }
      }
    );

    return {
      loaderEnabled,
      contentWidthFluid,
      loaderLogo,
      asideEnabled,
      subheaderDisplay,
      pageTitle,
      breadcrumbs,
      themeLightLogo,
    };
  },
});
