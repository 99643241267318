import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f119feb2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "kt_header",
  style: {},
  class: "header align-items-stretch start-0"
}
const _hoisted_2 = {
  class: "d-flex align-items-center d-lg-none ms-n3 me-1",
  title: "Show aside menu"
}
const _hoisted_3 = {
  class: "btn btn-icon btn-active-light-primary btn-nav",
  id: "kt_aside_mobile_toggle"
}
const _hoisted_4 = { class: "svg-icon svg-icon-2x mt-1" }
const _hoisted_5 = { class: "d-flex align-items-stretch flex-shrink-0 btn-nav" }
const _hoisted_6 = { class: "d-flex align-items-stretch justify-content-between flex-lg-grow-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_KTLogo = _resolveComponent("KTLogo")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass([{
        'container-fluid': _ctx.headerWidthFluid,
        'container-xxl': !_ctx.headerWidthFluid,
      }, "d-flex align-items-stretch justify-content-between mx-4"])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, [
            _createVNode(_component_inline_svg, { src: "svg/icons/abs015.svg" })
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_KTLogo)
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ], 2)
  ]))
}