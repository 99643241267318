
import { defineComponent } from "vue";
import { setModal } from "@/core/helpers/config";

export default defineComponent({
  props: {
    platformId: Number,
  },
  setup() {
    const createNewPlatform = async () => {
      await setModal("");
      setModal("KTCreatePlatformModal");
    };
    return { setModal, createNewPlatform };
  },
});
