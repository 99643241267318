
import { defineComponent, ref, computed, watch } from "vue";
import { setModal } from "@/core/helpers/config";
import ModalLayout from "@/components/modals/ModalLayout.vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { CreateNewTicketFormData } from "@/types/ticket";
import { supabase } from "@/service/SupabaseService";
import { GetListSelectTicketResponseDto } from "@/service/index";
import { useRoute } from "vue-router";
import NotifService from "@/core/services/NotifService";
import Upload from "@/components/shared/Upload.vue";
import UserInfo from "@/components/shared/UserInfo.vue";

export default defineComponent({
  name: "create-ticket-modal",
  components: { ModalLayout, Upload, UserInfo },
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  setup() {
    const formRef = ref<null | HTMLFormElement>(null);
    const uploadInput = ref<null | any>(null);
    const pages = ref<null | any>([]);
    const sections = ref<null | any>([]);
    const loading = ref<boolean>(false);
    const store = useStore();
    const route = useRoute();
    const PROJECT_ID = 1;
    /* to fix !  can not get platform id when open it from dashbord */
    const platformID = route.params["id"] as string;
    store.dispatch(Actions.FETCH_LIST_SELECT_TICKETS);
    const tickets = computed(() => {
      return store.getters.getTickets as GetListSelectTicketResponseDto;
    });
    watch(
      () => tickets.value.ticketTypes,
      (newValue) => {
        const pageTypeId = newValue?.find(({ name }) => name === "Page")?.id;

        if (pageTypeId)
          supabase
            .from("tickets")
            .select("id , key, title")
            .eq("ticketTypeId", pageTypeId)
            .eq("projectId", PROJECT_ID)
            .then(({ data }) => (pages.value = data));
      }
    );

    const targetData = ref<CreateNewTicketFormData>({
      ticketTitle: "",
      type: 1,
      skills: [],
      userJourney: [""],
      acceptanceTest: [""],
      projectId: PROJECT_ID,
    });

    const disabledDate = (time) => {
      return time.getTime() < Date.now();
    };
    let users = ref<any>([]);
    supabase
      .from("platform_user")
      .select()
      .eq("platformId", platformID)
      .then(({ data }) => {
        users.value = data?.map(({ userId, email, firstName, lastName }) => ({
          id: userId,
          email,
          name: `${firstName} ${lastName}`,
        }));
      });

    const loadSections = (e) => {
      const sectionTypeId = tickets.value.ticketTypes?.find(
        ({ name }) => name === "Section"
      )?.id;
      supabase
        .from("tickets")
        .select("id , key, title")
        .eq("ticketTypeId", sectionTypeId)
        .eq("parentTicketKey", e)
        .eq("projectId", PROJECT_ID)
        .then(({ data }) => (sections.value = data));
    };

    const rules = ref({
      ticketTitle: [
        {
          required: true,
          trigger: "change",
        },
      ],
      description: [
        {
          required: true,
          trigger: "change",
        },
      ],
      estimation: [
        {
          required: false,
          message: "estimation format invalid",
          trigger: "change",
          pattern:
            /^(?:\d+w)?(?:(?!\n)\s)?(?:\d+d)?(?:(?!\n)\s)?(?:\d+h?)?(?:(?!\n)\s)?(?:\d+m)?$/,
        },
      ],
      complexity: [
        {
          required: true,
          message: "Please select activity resource",
          trigger: "change",
        },
      ],
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          store
            .dispatch(Actions.SUBMIT_NEW_TICKET, targetData.value)
            .then((resp) => {
              console.log({ resp });
              loading.value = false;
              NotifService.displaySuccessAlert("ticket created");
              setModal("");
            })
            .catch((err) => {
              NotifService.displayErrorAlert(err.message);
            });
        }
      });
    };
    const addUserJourney = () => {
      targetData.value.userJourney?.push("");
    };
    const addAcceptanceTest = () => {
      targetData.value.acceptanceTest?.push("");
    };

    const removeUserJourney = (index) => {
      targetData.value.userJourney?.splice(index, 1);
    };

    const removeAcceptanceTest = (index) => {
      targetData.value.acceptanceTest?.splice(index, 1);
    };

    const resetRelates = () => {
      targetData.value.relatesPage = undefined;
      targetData.value.relatesSection = undefined;
    };

    const resetValue = () => {
      uploadInput.value?.clearFiles();
      formRef.value?.resetFields();
      setModal("");
    };

    return {
      targetData,
      submit,
      loading,
      formRef,
      rules,
      users,
      addUserJourney,
      removeUserJourney,
      removeAcceptanceTest,
      addAcceptanceTest,
      pages,
      sections,
      resetRelates,
      resetValue,
      disabledDate,
      uploadInput,
      setModal,
      tickets,
      loadSections,
    };
  },
});
