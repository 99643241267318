import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page d-flex flex-row flex-column-fluid" }
const _hoisted_2 = {
  id: "kt_wrapper",
  class: "d-flex flex-column flex-row-fluid wrapper"
}
const _hoisted_3 = { class: "d-flex align-items-stretch flex-shrink-0 logo-nav" }
const _hoisted_4 = {
  class: "d-flex align-items-stretch",
  id: "kt_header_menu_nav"
}
const _hoisted_5 = { class: "d-flex align-items-stretch flex-shrink-0" }
const _hoisted_6 = {
  id: "kt_content",
  class: "content pt-4 d-flex flex-column flex-column-fluid container-fluid"
}
const _hoisted_7 = { class: "post d-flex flex-column-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTLoader = _resolveComponent("KTLoader")!
  const _component_KTAside = _resolveComponent("KTAside")!
  const _component_Logo = _resolveComponent("Logo")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_Topbar = _resolveComponent("Topbar")!
  const _component_KTHeader = _resolveComponent("KTHeader")!
  const _component_KTToolbar = _resolveComponent("KTToolbar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_KTFooter = _resolveComponent("KTFooter")!
  const _component_ModalLoader = _resolveComponent("ModalLoader")!
  const _component_KTScrollTop = _resolveComponent("KTScrollTop")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loaderEnabled)
      ? (_openBlock(), _createBlock(_component_KTLoader, {
          key: 0,
          logo: _ctx.loaderLogo
        }, null, 8, ["logo"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.asideEnabled)
        ? (_openBlock(), _createBlock(_component_KTAside, {
            key: 0,
            lightLogo: _ctx.themeLightLogo
          }, null, 8, ["lightLogo"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_KTHeader, { title: _ctx.pageTitle }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_Logo)
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_Menu)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_Topbar)
            ])
          ]),
          _: 1
        }, 8, ["title"]),
        _createElementVNode("div", _hoisted_6, [
          (_ctx.subheaderDisplay)
            ? (_openBlock(), _createBlock(_component_KTToolbar, {
                key: 0,
                breadcrumbs: _ctx.breadcrumbs,
                title: _ctx.pageTitle
              }, null, 8, ["breadcrumbs", "title"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", {
              id: "kt_content_container",
              class: _normalizeClass([{
              'container-fluid': _ctx.contentWidthFluid,
            }, "w-100 mx-4"])
            }, [
              _createVNode(_component_router_view)
            ], 2)
          ])
        ]),
        _createVNode(_component_KTFooter)
      ])
    ]),
    _createVNode(_component_ModalLoader),
    _createVNode(_component_KTScrollTop)
  ], 64))
}