
import { defineComponent, ref } from "vue";

import ModalLayout from "@/components/modals/ModalLayout.vue";
export default defineComponent({
  components: { ModalLayout },
  name: "all-pull",
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  setup() {
    const prefixTicket = ref("DFP-286");

    const data = ref([
      {
        id: "#58",
        branche: "DFP-286 set typescript as lang",
        status: "MERGED",
        author: "kira",
        reviewer: "Ali",
        commenter: "3",
        updated: "25/10/2020",
      },
      {
        id: "#55",
        branche: "DFP-286 Create default values migration mechanism",
        status: "MERGED",
        author: "Hisoka",
        reviewer: "Gon",
        commenter: "5",
        updated: "23/10/2020",
      },

      {
        id: "#58",
        branche: "DFP-286 integrate member dashboard",
        status: "OPEN",
        author: "kira",
        reviewer: "Ali",
        commenter: "3",
        updated: "3 days ago",
      },
      {
        id: "#58",
        branche: "DFP-286 integrate member dashboard",
        status: "OPEN",
        author: "kira",
        reviewer: "Ali",
        commenter: "3",
        updated: "3 days ago",
      },
      {
        id: "#58",
        branche: "DFP-286 integrate member dashboard",
        status: "OPEN",
        author: "kira",
        reviewer: "Ali",
        commenter: "3",
        updated: "3 days ago",
      },
      {
        id: "#58",
        branche: "DFP-286 integrate member dashboard",
        status: "OPEN",
        author: "kira",
        reviewer: "Ali",
        commenter: "3",
        updated: "3 days ago",
      },
      {
        id: "#58",
        branche: "DFP-286 integrate member dashboard",
        status: "OPEN",
        author: "kira",
        reviewer: "Ali",
        commenter: "3",
        updated: "3 days ago",
      },
      {
        id: "#58",
        branche: "DFP-286 integrate member dashboard",
        status: "OPEN",
        author: "kira",
        reviewer: "Ali",
        commenter: "3",
        updated: "3 days ago",
      },
      {
        id: "#58",
        branche: "DFP-286 integrate member dashboard",
        status: "OPEN",
        author: "kira",
        reviewer: "Ali",
        commenter: "3",
        updated: "3 days ago",
      },
      {
        id: "#58",
        branche: "DFP-286 integrate member dashboard",
        status: "OPEN",
        author: "kira",
        reviewer: "Ali",
        commenter: "3",
        updated: "3 days ago",
      },
      {
        id: "#58",
        branche: "DFP-286 integrate member dashboard",
        status: "OPEN",
        author: "kira",
        reviewer: "Ali",
        commenter: "3",
        updated: "3 days ago",
      },
    ]);

    return {
      data,
      prefixTicket,
    };
  },
});
