<template>
  <div class="w-100 h-450px">
    <!--begin::Input group-->
    <div class="fv-row">
      <!--begin::Row-->
      <div class="row">
        <label class="d-flex align-items-center fs-6 fw-bold mb-2">
          <span class="required">
            {{ $t("chooseType") }}
          </span>
        </label>
        <Field name="platformType" v-model="platformTypeLocal">
          <!--begin::Col-->
          <div
            class="col-md-6 col-lg-4 mb-4 platform-select"
            v-for="type in platformTypes"
            :key="type.id"
          >
            <!--begin::Option-->

            <input
              type="checkbox"
              class="btn-check btn-check-type"
              name="platformType"
              :value="Object.assign({ id: type.id }, { name: type.name })"
              :id="`${type.id}`"
              v-model="platformTypeLocal"
            />
            <label
              class="
                btn btn-outline btn-outline-dashed btn-outline-default
                d-flex
                align-items-center
                mb-10
                p-4
                lh-1
              "
              :for="`${type.id}`"
            >
              <!--begin::Info-->
              <span class="d-block fw-bold">
                <span class="text-dark fw-bolder d-block mb-2">
                  {{ type.name }}
                </span>
                <span class="text-gray-400 fw-bold fs-8"
                  >If you need more info, please check it out</span
                >
              </span>
              <!--end::Info-->
            </label>
            <!--end::Option-->
          </div>
          <!--end::Col-->
        </Field>
        <ErrorMessage
          class="fv-plugins-message-container invalid-feedback fs-8"
          name="platformType"
        />
      </div>

      <!--Languages-->

      <div class="d-flex flex-column mt-4 fv-row">
        <!--begin::Label-->
        <label class="d-flex align-items-center fs-6 fw-bold mb-2">
          <span class="required">
            {{ $t("languages") }}
          </span>
        </label>

        <!--end::Label-->
        <Field name="languageSelected" v-model="languagesLocal">
          <el-select
            multiple
            filterable
            placeholder=""
            name="languageSelected"
            v-model="languagesLocal"
          >
            <el-option
              v-for="language in platformLanguages"
              :label="language.name"
              :value="language.id"
              :key="language.id"
            >
            </el-option>
          </el-select>
        </Field>
        <ErrorMessage
          class="fv-plugins-message-container invalid-feedback fs-8"
          name="languageSelected"
        />
      </div>
      <!--end::Row-->
    </div>
    <!--end::Input group-->
  </div>
</template>

<script>
import { ErrorMessage, Field } from "vee-validate";

export default {
  props: {
    languageSelected: Array,
    platformType: Array,
    platformTypes: Array,
    platformLanguages: Array,
  },
  components: {
    ErrorMessage,
    Field,
  },
  computed: {
    languagesLocal: {
      get: function () {
        return this.languageSelected;
      },
      set: function (value) {
        this.$emit("update:languageSelected", value);
      },
    },
    platformTypeLocal: {
      get: function () {
        return this.platformType;
      },
      set: function (value) {
        this.$emit("update:platformType", value);
      },
    },
  },
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.platform-select {
  position: relative;
}
.btn-check-type {
  position: absolute;
  clip: auto !important;
  pointer-events: auto !important;
  opacity: 0;
  width: 100%;
  height: 100%;
}
</style>
