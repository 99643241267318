
import { defineComponent, computed, ref } from "vue";

import { useStore } from "vuex";
import { setModal } from "@/core/helpers/config";
import ModalLayout from "@/components/modals/ModalLayout.vue";
import { Actions } from "@/store/enums/StoreEnums";
import { setInviteUser } from "@/core/helpers/user";
import type { ElForm } from "element-plus";

interface NewUserData {
  firstName: string;
  lastName: string;
  email: string;
  groups: Array<string>;
}

export default defineComponent({
  name: "addRole",
  components: { ModalLayout },
  props: {},
  setup() {
    const formRef = ref<null | HTMLFormElement>(null);
    const store = useStore();
    const loading = ref<boolean>(false);
    const checkAll = ref<boolean>(false);
    const isIndeterminate = ref<boolean>(true);
    store.dispatch(Actions.FETCH_GROUPS);
    const groups = computed(() => {
      return store.getters.getGroups;
    });

    const targetData = ref<NewUserData>({
      firstName: "",
      lastName: "",
      email: "",
      groups: [],
    });

    const rules = ref({
      firstName: [
        {
          required: true,
          message: "First Name is a required field",
          trigger: "change",
        },
      ],
      lastName: [
        {
          required: true,
          message: "Last Name is a required field",
          trigger: "change",
        },
      ],
      email: [
        {
          required: true,
          message: "email is a required field",
          trigger: "change",
        },
      ],
      groups: [
        {
          type: "array",
          required: true,
          message: "role is a required field",
        },
      ],
    });
    const resetValue = () => {
      targetData.value.firstName = "";
      targetData.value.lastName = "";
      targetData.value.email = "";
    };
    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          loading.value = true;

          await setInviteUser(targetData.value);
          loading.value = false;

          resetValue();
          setModal("");
        }
      });
    };
    const ruleFormRef = ref<InstanceType<typeof ElForm>>();
    const resetForm = (formEl: InstanceType<typeof ElForm> | undefined) => {
      if (!formEl) return;
      formEl.resetFields();
    };
    return {
      setModal,
      groups,
      rules,
      submit,
      formRef,
      targetData,
      loading,
      resetValue,
      isIndeterminate,
      checkAll,
      setInviteUser,
      resetForm,
      ruleFormRef,
    };
  },
});
