<template>
  <div class="w-100 h-450px mh-450px">
    <!--begin::Input group-->
    <div v-if="platformTypes">
      <div class="fv-row" v-for="(type, index) in checkedType" :key="index">
        <div class="fv-row">
          <div class="fv-row mt-4">
            <!--begin::Label-->
            <label class="d-flex align-items-center fs-5 fw-bold mb-4">
              <span>{{ type.name }}</span>
            </label>
            <!--end::Label-->

            <!--begin:Option-->
            <div class="technology">
              <Field name="technologyApplication">
                <ul>
                  <li
                    class="select-technology"
                    v-for="technology in platformTechnologies(
                      type,
                      platformTypes
                    )"
                    :key="technology.id"
                  >
                    <input
                      class="btn-check btn-check-type"
                      type="checkbox"
                      :id="technology.id"
                      :value="{
                        id: type.id,
                        name: type.name,
                        technologies: { name: technology.name },
                      }"
                      name="technologyApplication"
                      v-model="technologiesApplication"
                    />
                    <label :for="technology.id">
                      <img
                        v-if="technology.name"
                        :src="`svg/technology-logos/${technology.name}.svg`"
                      />
                      <span class="technology-name text-primary fw-bolder fs-8">
                        {{ technology.name }}</span
                      >
                    </label>
                  </li>
                </ul>
              </Field>
              <ErrorMessage
                class="fv-plugins-message-container invalid-feedback fs-8"
                name="technologyApplication"
              />
              <!--end::Option-->
            </div>
            <!--end::Option-->
          </div>
        </div>
        <!--end::Input group-->
      </div>
    </div>
  </div>
</template>

<script>
import { ErrorMessage, Field } from "vee-validate";

export default {
  props: {
    technologyApplication: Array,
    platformTypes: Array,
    checkedType: Array,
  },
  components: {
    ErrorMessage,
    Field,
  },
  computed: {
    technologiesApplication: {
      get: function () {
        return this.technologyApplication;
      },
      set: function (value) {
        this.$emit("update:technologyApplication", value);
      },
    },
  },
  setup() {
    function platformTechnologies(valType, technologies) {
      const platformType = technologies.filter((e) => {
        if (e.name == valType.name) return e;
      })[0].technologies;
      return platformType;
    }
    return {
      platformTechnologies,
    };
  },
};
</script>

<style lang="scss" scoped>
.technology {
  ul {
    list-style-type: none;
    padding-left: 0;
    flex-wrap: wrap;
    display: flex;
    gap: 2rem;

    li {
      display: inline-block;

      border-radius: 10px;
      background-color: #f5f8fa;
    }
    label {
      display: inline-flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }

  label {
    border: 1px solid #fff;
    display: block;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    justify-content: center;
    cursor: pointer;
    border-radius: 10px;
    height: 100%;
  }

  label:before {
    background-color: white;
    color: white;
    content: " ";
    display: block;
    border-radius: 50%;
    border: 1px solid grey;
    position: absolute;
    top: -5px;
    left: -5px;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 28px;
    transition-duration: 0.4s;
    transform: scale(0);
  }

  label img {
    width: 40px;
    object-fit: scale-down;
    margin: 10px 20px;
    height: 40px;
  }

  .technology-name {
    width: 85px;
  }
  :checked + label {
    border-style: dashed;
    border-color: #7239ea;
  }
  .scale1-5 {
    transform: scale(1.5);
  }
}
.btn-check-type {
  position: absolute;
  clip: auto !important;
  pointer-events: auto !important;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.select-technology {
  position: relative;
}
</style>
