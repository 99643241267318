
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { StepperComponent } from "@/assets/ts/components/_StepperComponent";
import { useForm } from "vee-validate";
import useTranslate from "@/core/composable/useTranslate";
import * as Yup from "yup";
import Step1 from "@/components/modals/wizards/createPlatform/Step1.vue";
import Step2 from "@/components/modals/wizards/createPlatform/Step2.vue";
import Step3 from "@/components/modals/wizards/createPlatform/Step3.vue";
import Step4 from "@/components/modals/wizards/createPlatform/Step4.vue";
import Step5 from "@/components/modals/wizards/createPlatform/Step5.vue";
import Nav from "@/components/modals/wizards/createPlatform/Nav.vue";
import ModalLayout from "@/components/modals/ModalLayout.vue";
import { useStore } from "vuex";
import { setPlatformInfo } from "@/core/helpers/platform";
import { Actions } from "@/store/enums/StoreEnums";
import { supabase } from "@/service/SupabaseService";
import { definitions } from "@/types/supabase";

interface IStep1 {
  releaseDate: Date;
  activities: Array<string>;
  platformName: string;
  platformDescription: string;
  keyValue?: string;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  imagesUrl: Array<any>;
}

interface IStep2 {
  groupSelected: Array<number>;
  teamManager?: number;
}
interface IStep3 {
  platformType: Array<any>;
  languageSelected: Array<number>;
}
interface IStep4 {
  technologiesApplication: Array<any>;
}
interface KTCreatePlatform extends IStep1, IStep2, IStep3, IStep4 {}
export default defineComponent({
  name: "create-platform-modal",
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    Nav,
    ModalLayout,
  },
  setup() {
    const _stepperObj = ref<StepperComponent | null>(null);
    const createPlatformRef = ref<HTMLElement | null>(null);
    const createPlatformModalRef = ref<HTMLElement | null>(null);
    const formRef = ref<null | HTMLFormElement>(null);
    const currentStepIndex = ref(0);
    const platformId = ref(0);
    const uploadInput = ref<null | any>(null);
    const store = useStore();
    const loading = ref<boolean>(false);
    const keyPrefix = ref<null | any>([]);
    const users = ref<null | any>([]);
    const formData = ref<KTCreatePlatform>({
      releaseDate: new Date(),
      keyValue: "",
      activities: ["Science"],
      platformName: "",
      platformDescription: "",
      imagesUrl: [],
      groupSelected: [1],
      platformType: [{ id: 1, name: "Web Application" }],
      languageSelected: [1],
      technologiesApplication: [],
    });
    const { translate } = useTranslate();
    store.dispatch(Actions.FETCH_FORM_INFO_PLATFORM);
    const platformFormInfo = computed(() => {
      return store.getters.platformFormInfos;
    });
    onMounted(() => {
      _stepperObj.value = StepperComponent.createInsance(
        createPlatformRef.value as HTMLElement
      );
    });

    // supabase methode
    watch(
      () => formData.value.groupSelected,
      (newListGroup) => {
        supabase
          .from<definitions["users_groups"]>("users_groups")
          .select(`users (id , firstName , lastName)`)
          .in("groupsId", newListGroup)
          .then(({ data }: any) => {
            users.value = data?.reduce((acc, current) => {
              const isFindId = acc.find(
                (item) => item.users.id === current.users.id
              );
              if (!isFindId) {
                return acc.concat([current]);
              } else {
                return acc;
              }
            }, []);

            if (data && data[0]) formData.value.teamManager = data[0].users.id;
          });
      }
    );
    watch(
      () => formData.value.keyValue,
      (keyPlatform) => {
        supabase
          .from<definitions["platforms"]>("platforms")
          .select(`ticketsPrefix`)
          .eq("ticketsPrefix", keyPlatform)
          .then(({ data, error }: any) => {
            console.log({ data, error });
            keyPrefix.value = data;
          });
      }
    );
    // end supabase methode
    const createPlatformResponse = computed(() => {
      return store.getters.createPlatformResponse;
    });
    const createPlatformSchema = [
      Yup.object().validateSync({
        platformName: Yup.string()
          .required(` ${translate("platformNameRequired")}`)
          .label("Platform Name"),
        activities: Yup.array()
          .min(1, ` ${translate("activities")}  ${translate("required")}`)
          .required()
          .label("Field of activities"),
        releaseDate: Yup.string()
          .required(` ${translate("releaseDate")}  ${translate("required")}`)
          .label("Release Date"),
        keyValue: Yup.string()
          .required(` ${translate("key")}  ${translate("required")}`)
          .max(4, ` ${translate("maxKeyLenght")}`)
          .matches(/^([A-Z])+$/, ` ${translate("keyRequired")}`)
          .label("key"),
      }),
      Yup.object({
        teamManager: Yup.string()
          .required(` ${translate("teamManagerfield")}`)
          .label("Team Manager"),
      }),
      Yup.object({
        languageSelected: Yup.array()
          .default([])
          .min(0, ` ${translate("languages")}  ${translate("required")}`)
          .required()
          .label("Languages"),
        platformType: Yup.array()
          .default([])
          .min(1, ` ${translate("platformType")}  ${translate("required")}`)
          .required()
          .label("Platform type"),
      }),
      Yup.object({}),
    ];

    // extracts the individual step schema
    const currentSchema = computed(() => {
      return createPlatformSchema[currentStepIndex.value];
    });

    const generateKey = () => {
      formData.value.keyValue = formData.value.platformName
        ?.toUpperCase()
        .match(/\b([A-Z])/g)
        ?.join("");
    };

    const totalSteps = computed(() => {
      if (!_stepperObj.value) {
        return;
      }

      return _stepperObj.value.totatStepsNumber;
    });

    const { resetForm, handleSubmit } = useForm<
      IStep1 | IStep2 | IStep3 | IStep4
    >({
      validationSchema: currentSchema,
    });

    const previousStep = () => {
      if (!_stepperObj.value) {
        return;
      }

      currentStepIndex.value--;

      _stepperObj.value.goPrev();
    };
    const restStepIndex = () => {
      if (!_stepperObj.value) {
        return;
      }

      currentStepIndex.value = 0;
      _stepperObj.value.goto(1);
    };

    const handleStep = handleSubmit((values) => {
      formData.value = {
        ...formData.value,
        ...values,
      };

      if (platformId.value === 0 && currentStepIndex.value !== 3) {
        currentStepIndex.value++;
        if (!_stepperObj.value) {
          return;
        }
        _stepperObj.value.goNext();
      }
    });
    resetForm({
      values: {
        ...formData.value,
      },
    });

    const formSubmit = async () => {
      loading.value = true;
      platformId.value = await setPlatformInfo(formData.value);
      loading.value = false;
      currentStepIndex.value++;
      if (!_stepperObj.value) {
        return;
      }
      _stepperObj.value.goto(5);
    };

    return {
      previousStep,
      handleStep,
      formSubmit,
      restStepIndex,
      generateKey,
      createPlatformRef,
      totalSteps,
      currentStepIndex,
      formData,
      createPlatformModalRef,
      currentSchema,
      users,
      formRef,
      uploadInput,
      createPlatformResponse,
      platformFormInfo,
      keyPrefix,
      platformId,
      loading,
    };
  },
});
